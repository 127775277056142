import React from 'react'

function Pagenation(props) {
    const clickDot = (dot) =>{
        props.onChangeIndex(dot)
    }
    return (
        <>
         <ul className='pagination'>
             {props.dots.map((dot, index) => {
                 return(
                     <li key={index} className="dotList">
                         <button
                         name={dot}
                         className={ (dot === props.currentIndex)? 'dot active' : 'dot'}
                         onClick = {clickDot.bind(this, dot)}
                         >
                         </button>
                     </li>
                 )
             })}
             </ul>   
        </>
    )
}

export default Pagenation
