import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(2),
            width: 'calc(100% - 40px)',
            border: 0,
        },
    },
    submitButtom: {
        border: 0,
        fontSize: '24px',
        maxWidth: '300px',
        display: 'block',
        margin: '30px auto',
        background: '#0F88F2',
        color: '#ffffff',
        '&:hover': {
            background: '#57AAF2',
        },
    },
    yesButtom: {
        border: 0,
        padding: '5px 30px',
        display: 'block',
        background: '#0F88F2',
        color: '#ffffff',
        '&:hover': {
            background: '#57AAF2',
        },
    },
}));

// 半角から全角変換
function hankaku2Zenkaku(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}

function Contact() {
    const classes = useStyles();
    // Stateの設定
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [inquiry, setInquiry] = useState('')

    const handleSetName = () => {
        const value = document.getElementById('name').value
        setName(value)
    }

    const handleSetMail = () => {
        const value = document.getElementById('mail').value
        setMail(value)
    }

    const handleSetPhonNumber = () => {
        const value = document.getElementById('phoneNumber').value
        setPhoneNumber(value)
    }

    const handleSetInquiry = () => {
        const value = document.getElementById('inquiry').value
        setInquiry(value)
    }

    // validation
    const [validName, setValidName] = useState('')
    const [validMail, setValidMail] = useState('')
    const [validMailComment, setValidMailComment] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState('')

    const handleSetValidName = () => {
        const value = document.getElementById('name').value
        if (value !== '') {
            setValidName(200)
            console.log(200)
        } else {
            setValidName(400)
            console.log(400)
        }
    }
    const handleSetValidMail = () => {
        let value = mail
        value = hankaku2Zenkaku(value)
        if (value === '') {
            setValidMailComment('メールアドレスを入力してください')
            setValidMail(400)
        } else {
            const regex = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (!regex.test(value)) {
                setValidMailComment('正しい形式でメールアドレスを入力してください')
                setValidMail(400)
            } else {
                setValidMail(200)
            }

        }

    }

    const handleSetValidPhonNumber = () => {
        const value = phoneNumber
        if (value === '') {
            setValidPhoneNumber(400)
        } else {
            let tel = value.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '');
            tel = hankaku2Zenkaku(tel)
            if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
                setValidPhoneNumber(400)
            } else {
                setValidPhoneNumber(200)
            }
        }

    }

    // 送信アクション
    const handleSubmitAction = async (event) => {
        handleSetValidName()
        handleSetValidMail()
        handleSetValidPhonNumber()
        if (validName === 200 && validMail === 200 && validPhoneNumber === 200) {
            console.log('Submit action')
            handleModalClickOpen();
        } else {
            // 名前検査
        
            const getnameValid = () => new Promise((resolve) => { 
                const nameValue = document.getElementById('name').value
                if (nameValue !== '') {
                    resolve(200)
                } else {
                    resolve(400)
                }
             });
             // メール検査
             const getmailValid = () => new Promise((resolve)=>{
                  // メール検査
                  
                    let mailValue = document.getElementById('mail').value
                    mailValue = hankaku2Zenkaku(mailValue)
                    if (mailValue === '') {
                        setValidMailComment('メールアドレスを入力してください')
                        resolve(400)
                    } else {
                        const regex = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        if (!regex.test(mailValue)) {
                            setValidMailComment('正しい形式でメールアドレスを入力してください')
                            resolve(400)
                        } else {
                            resolve(200)
                        }
                    }
             })

             // 電話番号検査
             const getphoneNumberValid = () => new Promise((resolve) =>{
                
                let phoneNumberValue = document.getElementById('phoneNumber').value
                if (phoneNumberValue === '') {
                    resolve(400)
                } else {
                    let tel = phoneNumberValue.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '');
                    tel = hankaku2Zenkaku(tel)
                    if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
                        resolve(400)
                    } else {
                        resolve(200)
                    }
                }
             })
            const [ nameValid,  mailValid, phoneNumberValid ] = await Promise.all([ 
                getnameValid(),
                getmailValid(),
                getphoneNumberValid(),
              ]);
            
            // 再検査実施
            if (nameValid === 200 && mailValid === 200 && phoneNumberValid === 200) {
                console.log('Submit action')
                handleModalClickOpen();
            } else {
                handlePopover(event.currentTarget)
            }
        }
    }

    // Popover
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopover = (currentTarget) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // ModalWindow
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClickOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // Mailer
    const handleSubmitMail = () => {
        handleShowProgressCircular();
        fetch(`https://e-syukatu.jp/mailer.php`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    name: name,
                    mail: mail,
                    phoneNumber: phoneNumber,
                    inquiry: inquiry
                })
        }).then(() => {
            handleHideProgressCircular()
            handleModalClose()
            handleModalOpenAlert()
            // Reset処理
            setName('')
            setMail('')
            setPhoneNumber('')
            setInquiry('')
        }
        ).catch((e) => {
            handleHideProgressCircular()
            handleModalClose()
            console.log('Error : ', e.message)
            window.alert('お問い合わせ送信に失敗しました :', e.message)
        })
    }
    // お問い合わせ送信完了モーダルの表示
    const [modalOpenAlert, setModalOpenAlert] = useState(false);
    const handleModalOpenAlert = () => {
        setModalOpenAlert(true)
    }
    const handleModalCloseAlert = () => {
        setModalOpenAlert(false)
    }
    // お問い合わせ送信中プログレスサークルの表示
    const [showProgressCircular, setShowProgressCircular] = useState(false);
    const handleShowProgressCircular = () => {
        setShowProgressCircular(true)
    }
    const handleHideProgressCircular = () => {
        setShowProgressCircular(false)
    }
    return (
        <>
            <section id="contact" className="contents">
                <h2>お問い合わせ<span className="en">- Contact -</span></h2>
                <p className="txt_c">以下のフォームよりお問い合わせできます<br />送信後、後日担当者よりご連絡させていただきます。</p>
                <div className="formWrapper">
                    <form className={classes.root} noValidate autoComplete="off">
                        <legend>お問い合わせフォーム</legend>
                        <p>必須項目を入力の後、「送信する」のボタンの押してください</p>
                        <TextField id="name" label="名前（必須）"
                            value={name}
                            onChange={handleSetName}
                            onBlur={handleSetValidName}
                            error={(validName === 400) ? true : false}
                            helperText={(validName === 400) ? '必須項目です正しく入力して下さい' : ''}
                        />
                        <TextField id="mail" label="メールアドレス（必須）"
                            value={mail}
                            onChange={handleSetMail}
                            onBlur={handleSetValidMail}
                            error={(validMail === 400) ? true : false}
                            helperText={(validMail === 400) ? validMailComment : ''}
                        />
                        <TextField id="phoneNumber" label="電話番号（必須）"
                            value={phoneNumber}
                            onChange={handleSetPhonNumber}
                            onBlur={handleSetValidPhonNumber}
                            error={(validPhoneNumber === 400) ? true : false}
                            helperText={(validPhoneNumber === 400) ? '必須項目です正しく入力して下さい' : ''}
                        />
                        <TextField
                            id="inquiry"
                            label="お問い合わせ内容"
                            multiline
                            rows={6}
                            defaultValue=""
                            value={inquiry}
                            onChange={handleSetInquiry}
                        />
                        <Button color="primary"
                            className={classes.submitButtom}
                            onClick={handleSubmitAction}
                        >送信する </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography
                                className={classes.typography}
                            >赤字の項目の修正お願いします</Typography>
                        </Popover>
                        <Dialog
                            open={modalOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleModalClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"お問い合わせ内容の確認"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description" style={{ wordWrap: "break-word" }}>
                                    以下の内容でお問い合わせフォームを送信してもよろしいでしょうか？<br />
                                    <span style={{ margin: "0 0 10px", fontSize: '14px' }}><br /><b>名前</b>：{name}</span>
                                    <span style={{ margin: "0 0 10px", fontSize: '14px' }}><br /><b>メールアドレス</b>：{mail}</span>
                                    <span style={{ margin: "0 0 10px", fontSize: '14px' }}><br /><b>電話番号</b>：{phoneNumber}</span>
                                    <span style={{ margin: "0 0 10px", fontSize: '14px' }}><br /><b>お問い合わせ内容</b>：<br />{
                                        (inquiry.indexOf('\n') !== -1) ? (
                                            inquiry.split('\n').map((str, index) => {
                                                return (
                                                    <React.Fragment key={index}>{str}<br /></React.Fragment>)
                                            })
                                        ) : (
                                                inquiry
                                            )}</span>
                                    <div className={showProgressCircular ? "active circularProgressWrapper" : "circularProgressWrapper"}>
                                        <div className="circularProgressBox">
                                            <CircularProgress
                                                size={70}
                                            />
                                        </div>
                                    </div>



                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleModalClose} color="primary">
                                    いいえ
          </Button>
                                <Button onClick={handleSubmitMail} color="primary" className={classes.yesButtom}>
                                    はい
          </Button>

                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={modalOpenAlert}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleModalCloseAlert}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"お問い合わせ送信完了"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    お問い合わせの送信が完了しました。<br />2-3営業日内に担当者より、ご連絡させていただきます。<br />担当者からの連絡がない場合は、再度HPよりお問い合わせ、またはお電話にてお問い合わせください。
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleModalCloseAlert} color="primary">
                                    閉じる
          </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                    <p>※内容によっては回答できない場合もございますのであらかじめご了承ください。<br />※ご登録いただいた個人情報は、当法人の個人情報保護指針にもとづいて適正に管理・取り扱いをさせていただきます。</p>
                </div>

            </section>
        </>
    )
}

export default Contact
