import React from 'react'
import image1 from '../assets/images/action1.jpg'
import image2 from '../assets/images/action2.jpg'
import image3 from '../assets/images/action3.jpg'

function Actions() {
    return (
        <>
            <section id="case" className="contents">
                <h2>事例　私たちの活動<span className="en">- Case -</span></h2>
                <div className="actionsWrapper">
                <section className="actionsList action1">
                    <div className="textBox">
                        <h3>身寄りのない方の葬儀埋葬委任契約</h3>
                        <p>ずっと一緒に暮らしてきたパートナーと死別したAさん。</p>
                        <p>子供はおらず、親戚の兄妹も遠い場所に住んでいて、ご自身の葬儀や埋葬について頼める方がいなく何か方法がないか探していました。</p>
                        <p>ご紹介にて当法人を知っていただき葬儀埋葬委任契約を結ぶことになりました。葬儀と埋葬責任をもって行うことになりました。</p>
                    </div>
                    <div className="imageBox">
                        <img src={image1} alt="葬儀埋葬委任契約のイメージ図"/>
                    </div>
                </section>
                <section className="actionsList action2">
                    <div className="textBox">
                        <h3>子供のために葬儀の事前契約と費用のお預かり</h3>
                        <p>子供に迷惑をかけたくないとのことで、事前に葬儀と納骨の事前契約をしたご夫婦。</p>
                        <p>契約に際して、費用の預かりを行わせていただきました。万が一の際も、ご契約をもとにしてお預かりさせていただいた費用からお支払いを行います。</p>
                    </div>
                    <div className="imageBox">
                        <img src={image2} alt="費用のお預かりのイメージ図"/>
                    </div>
                </section>
                <section className="actionsList action3">
                    <div className="textBox">
                        <h3>合同供養の実施</h3>
                        <p>お遺骨の引き取りに困られた方の供養を行っております。</p>
                        <p>自治体や士業などからお遺骨の引き取り手のない方のご納骨の依頼があり、当法人では指定寺院の合祀墓にて合同供養を行わせていただきています。</p>
                    </div>
                    <div className="imageBox">
                        <img src={image3}　alt="合同供養の実施写真" />
                    </div>
                </section>
                </div>
            </section>
        </>
    )
}

export default Actions
