import React from 'react'
import {  ContactButton } from './index' 
import '../assets/footer.css'
function Footer() {
    return (
        <>
          <footer>
              <div className="footerWrapper">
                  <address>
                      <span className="logo"><span className="spBlock">一般社団法人 </span><span className="title spBlock">終活ケアサポート</span></span><br />
                      〒164-0003　東京都中野区東中野3-17-15 3F<br />
                      TEL　0120-918-172(通話料無料)
                  </address>
                  <div className="buttonWrapper">
                      <ContactButton />
                  </div>
                  <div className="cap txt_c"><span className="block">Copyright ©  2021 一般社団法人 終活ケアサポート</span><span className="block">All Rights Reserved</span></div>
            </div>
          </footer>
        </>
    )
}

export default Footer
