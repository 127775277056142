import React, {useState, useEffect} from 'react'
import { Header, 
    TopImage, 
    ScrollBtn, 
    Detaile, 
    Vision, 
    Actions, 
    Message, 
    About, 
    Contact, 
    Footer,
    Menue } from './components/index'
import { ParallaxProvider } from 'react-scroll-parallax';
import './assets/main.css'
import './assets/nomalize.css'
// スクロール量

function Home() {
    
    const [scrollY, setScrollY] = useState(0)
    // 現在地の取得
    window.onscroll = () =>{
        const y = window.pageYOffset ;
        const windoHeignt = window.innerHeight;
        const viewBottom = y + windoHeignt
        setScrollY(viewBottom)
    }
    useEffect(() => {
        const removeLoading = () =>{
            const loadElem = document.getElementById('loading')
        const body = document.body
        loadElem.style.opacity = 0
        loadElem.style.visibility = 'hidden'
        body.style.position = 'relative'
        }
        window.setTimeout(removeLoading, 800)
    }, [])
    return (
        <>
        <ParallaxProvider>
        <Header />
        <Menue />
        <TopImage />
        <ScrollBtn />
        <Detaile 
        scrollY = {scrollY}
         />
         <Vision />
         <Actions 
         scrollY = {scrollY}
         />
         <Message />
         <About />
         <Contact />
         <Footer />
         </ParallaxProvider>
        </>
    )
}

export default Home
