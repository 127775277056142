import React from 'react'
import topImage from '../assets/images/top_image_1.jpg'
function TopImage() {
    return (
        <>
            <div className="topImageWrapper">
                <img src={topImage} alt="Top画像" />
                <div className="textBoxWrapper">
                    <div className="textBox">
                        <p>
                            葬儀埋葬<br />
                            委任契約で、<br />
                            安心の生活を
                        </p>
                        <p className="small">私たちは葬儀埋葬委任契約を中心に<br />様々な終活サポートを実施しています</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopImage
