import React, { useState } from 'react'

import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai'
import { IconContext } from 'react-icons';
import { MenueData } from './index'

function Menue() {
    // メニューバーの開閉のstate
    const [menuBarisOpend, setmenuBarisOpend] = useState(false);
    
    const shoemMenuBar = () => setmenuBarisOpend(!menuBarisOpend);

    return (
        <>
            <IconContext.Provider value={{color :'#ffffff'}} >
             <div className="navbar">
                 
                 <div className="menuBars" onClick={shoemMenuBar}>
                     <FaIcons.FaBars style={{color :'#056CF2'}}/>
                 </div>
             </div>
             <nav className={menuBarisOpend ? 'navMenu active' : 'navMenu'} onClick={shoemMenuBar}>
                 <ul className='navMenuItems' >
                     <li className="nabvarToggle">
                         <div className='menuBars' >
                            <AiIcons.AiOutlineClose />
                         </div>
                     </li>
                     {MenueData.map((item, index) => {
                         return (
                            <li key={index} className='navMenuItem'>
                            <a href={item.link}>
                            <AiIcons.AiOutlineRight /><span>{item.heading}</span>
                            </a>
                        </li>
                         )
                     })}


                 </ul>
                 
             </nav>
             </IconContext.Provider>
        </>
    )
}

export default Menue
