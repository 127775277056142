export const MenueData  = [
    {
    heading: '私たちの事業内容',
       id: 'Detaile',
       link: '#detaile',
       index: 0
   },{
    heading: '私たちのめざすところ',
    id: 'Vision',
    link: '#vision',
    index: 1
},{
    heading: '事例　具体的な活動',
    id: 'Case',
    link: '#case',
    index: 2
},{
    heading: '代表の挨拶',
    id: 'Message',
    link: '#message',
    index: 3
},{
    heading: '私たちについて',
    id: 'About',
    link: '#about',
    index: 4
},{
    heading: 'お問い合わせ',
    id: 'Contact',
    link: '#contact',
    index: 5
}
]