import React from 'react'
import '../assets/header.css'
import * as AiIcons from 'react-icons/ai';
import Image from '../assets/images/tel_header.png'

function Header() {
    return (
        <>
            <header>
             <div className="headerWrapper">  
                <h1><span className="spBlock">一般社団法人</span><span className="title spBlock">終活ケアサポート</span></h1>
                <div className="contactButtonWrapper">
                    <a className="contactButtonTel" href="tel:0120-918-172"><span className="pc txt_c"><img src={Image} alt="電話での相談はこちらから0120-918-172" /></span><span className="sp"><AiIcons.AiFillPhone />電話で相談する</span></a>
                        <a  href="#contact">  
                                <div className="contactButton">お問い合わせはこちら
                                    </div>
                        </a>
                    
                </div>
             </div>
        </header>
        </>
    )
}

export default Header
