import React from 'react'
import { Parallax } from 'react-scroll-parallax';
import image from '../assets/images/background.png'
import image2 from '../assets/images/background2.png'

function Vision() {
    return (
        <div className="parallaxWrapper">
            <section id="vision" className="contents">

                <h2>私たちのめざすところ<span className="en">- Vision -</span></h2>
                <div className="contentsWrapper visionWrapper">
                    <div className="visionWrapperBox left">
                        <p>自分で決める終活が<br />当たり前になる<br />未来を目指す</p>
                    </div>
                    <div className="canvas">
                        <div className="line top"></div>
                        <div className="circle"></div>
                        <div className="line bottom"></div>
                    </div>
                    <div className="visionWrapperBox right">
                        <p>「終活」ということが浸透して10年以上が経ち、ご自身がいなくなったあとのことを考えられる方が増えました。
                    </p>
                        <p>一方で必要なサービスが必要な方へ提供されておらず、その不安が増すばかりという方もいます。</p>
                        <p>また多様な家族観のもとそれぞれの暮らし方に合わせた終活が必要とされています。</p>
                        <p>当法人では、すべての方がご自身の希望に合わせた終活を行えるように、葬儀埋葬委任契約を中心に終活支援活動を行っています。</p>
                    </div>
                </div>
                <p className="txt_c midashi01">
                    新しい終活を当たり前に<br />
                    <span className="en">New Standard</span>
                </p>
            </section>
            <Parallax className="parallaxBackground" y={[-30, 30]} tagOuter="figure">
                <img src={image} alt="背景" />
            </Parallax>
            <Parallax className="parallaxBackground" y={[-50, 50]} tagOuter="figure">
                <img src={image2} alt="背景" />
            </Parallax>
        </div>
    )
}

export default Vision
