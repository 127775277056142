import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Iframe from 'react-iframe'

const useStyles = makeStyles({
    table: {
        width: '100%',
        borderTop: '1px solid rgba(224, 224, 224, 1);', 
        padding: '10px'
    },
    cell:{
        fontFamily : 'fot-tsukuardgothic-std, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;'
    },
    tableWrapper :{
        width: '50%',
        
    },
    mapWrapper:{
        width: '50%',
        padding: '0 20px',
    },
    mapBox:{
        width: '100%', 
        height: '300px',
        border: 0,
    }
});

function createData(name, data) {
    return { name, data};
}

const rows = [
    createData('名称', '一般社団法人終活ケアサポート'),
    createData('アクセス', '東京都中野区東中野3-17-15 3F'),
    createData('設立', '2021年1月'),
    createData('役員', '代表理事　秋野圭崇'),
    createData('電話番号', <a href="tel:0120-918-172" style={{fontSize: '0.875rem'}}>0120-918-172</a>),
];

function About() {
    const classes = useStyles();
    return (
        <>
            <section id="about" className="contents">
                <h2>私たちについて<span className="en">- About -</span></h2>
                <div className="aboutWrapper">
                    
                    <TableContainer className='tableWrapper' >
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" style={{fontWeight: 900}} className={classes.cell} scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell className={classes.cell}>{row.data}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mapWrapper">
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5448.504563697586!2d139.67750547899746!3d35.70899459095847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f2b786ae3f9b%3A0x5ff62d2fe8b182e9!2z44CSMTY0LTAwMDMg5p2x5Lqs6YO95Lit6YeO5Yy65p2x5Lit6YeO77yT5LiB55uu77yR77yX4oiS77yR77yV!5e0!3m2!1sja!2sjp!4v1610681382212!5m2!1sja!2sjp"
                        width="450px"
                        height="450px"
                        id="myId"
                        title="地図Google map"
                        className="iframe"
                        display="initial"
                        position="relative"/>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About
