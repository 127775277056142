import React from 'react'
import '../assets/scrollBtn.css'
function ScrollBtn() {
    return (
        <>
        <div className="scrollBtnWrapper">
        <span className="scrollBtn">
                    <span className="mouse">
                        <span>
                        </span>
                    </span>
                <p>スクロール</p>

            </span>
        </div>
            
        </>
    )
}

export default ScrollBtn
