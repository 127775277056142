import React, { useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views';
import { DetaileData, Pagenation } from './index'
import { bindKeyboard } from 'react-swipeable-views-utils';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function Detaile(props) {

    // コンテンツ高さの取得
    const [scrollTop, setScrollTop] = useState(80000)
    useEffect(()=>{
        const elem = document.getElementById('detaileContents')
        var rect = elem.getBoundingClientRect();
        var elemtop = rect.top + window.pageYOffset;
        setScrollTop(elemtop)
    },[])

    // ScrollAnimationFadein
    useEffect(() => {
        if(props.scrollY - 60 > scrollTop){
            const elem = document.getElementById('detaileContents')
            elem.classList.add('active')
        }
    }, [props.scrollY, scrollTop])
    //Pagenation
    const [pagenationIndex, setPagenatioIndex] = useState(0);
    const handleChangeIndex = (index) => setPagenatioIndex(index);

    return (
        <>
            <section id="detaile" className="contents">
                <h2>私たちの事業内容<span className="en">- Detaile -</span></h2>
                <div id='detaileContents' className="contentsWrapper">
                    <p className="txt_c">私たちは葬儀埋葬委任契約を中心に<br />終活に係る様々な活動を行っています</p>
                    <div className="SwipeableViewsWrapper">
                        <div className="SwipeableViewsSlider">
                            <BindKeyboardSwipeableViews resistance enableMouseEvents index={pagenationIndex} onChangeIndex={handleChangeIndex} style={{ overflowX: 'visible' }}>
                                {DetaileData.map((item, index) => {
                                    return (
                                        <section key={index} className='slide'>
                                            <div className="imageBox">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <div className="textBox">
                                                <h3>{item.title}</h3>
                                                <p>{item.discript}</p>
                                            </div>
                                            
                                        </section>
                                    )
                                })}
                            </BindKeyboardSwipeableViews>
                        </div>
                        <Pagenation className="paginationdots" dots={[0, 1, 2, 3, 4]} currentIndex={pagenationIndex} onChangeIndex={handleChangeIndex} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Detaile
