import React from 'react'
import image from '../assets/images/message.jpg'

function Message() {
    return (
        <>
            <section id="message" className="contents">
                <h2>代表の挨拶<span className="en">- Message -</span></h2>
                <section className="messageWrapper">
                    <div className="imageBox">
                        <img src={image} alt="代表　秋野圭崇"/>
                    </div>
                    <div className="textBox">
                        <h3>代表　秋野 圭崇 
                        </h3>
                        <p>終活という言葉が広く普及して10年以上が経ちます。私は長年葬祭関係の会社で働く中で、たくさんの方のお葬式やご供養のご相談を受けてきました。</p>
                        <p>その中で身寄りがいなく自分がいなくあった後のことの心配をされる方をたくさん見てきました。現在の制度でも亡くなった後の契約をすることはできますが、安心安全の懸念や費用が高かったりするため諦められている方をたくさん見てきました。</p>
                        <p>私たちは誰もが自分らしい最後を選択することができるように、葬儀埋葬委任契約を中心としたシステムをご提供するために本法人を立ち上げました。私たちは「自分で決める終活が当たり前になる未来」を目指しています。</p>
                        
                    </div>
                </section>
            </section>
        </>
    )
}

export default Message
