import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as AiIcons from 'react-icons/ai';
const useStyles = makeStyles((theme) => ({
    contactButtom: {
        border: 0,
        borderRadius: 0,
        fontSize: '14px',
        maxWidth: '350px',
        padding: '5px 30px',
        display: 'block',
        background: '#FFFFFF',
        color: '#2E97F2',
        '&:hover': {
            color: '#056CF2',
            background: '#FFFFFF',
        },
    },
    aTag: {
        display: 'inline-block',
        margin: 0,
    }

}));

function ContactButton() {
    const classes = useStyles();
    return (
        <><a href="#contact" className={classes.aTag}>
            <Button color="primary"
                            className={classes.contactButtom}
                        >お問い合わせ  <AiIcons.AiOutlineRight /> </Button>
        </a>
            
        </>
    )
}

export default ContactButton
