
import image1 from '../assets/images/detaile1.jpg'
import image2 from '../assets/images/detaile2.jpg'
import image3 from '../assets/images/detaile3.jpg'
import image4 from '../assets/images/detaile4.jpg'
import image5 from '../assets/images/detaile6.jpg'

export const DetaileData  = [
     {
        title: '葬儀埋葬委任契約',
        id: 'keiyaku',
        discript: '葬儀埋葬委任契約で事前に葬儀と納骨について取り決めを行うことができます。公正証書にすることで法的な効力を持たせることも可能。',
        index: 0,
        image: image1
    },
    {
        title: '葬儀埋葬費用のお預かり',
        id: 'receive',
        discript: '事前契約での葬儀やご納骨の費用についてお預かりします。費用のお支払いは葬儀埋葬後に行われます。信用会社の使用でより安心安全に費用のお預かりをすることも可能。',
        index: 1,
        image: image2
    },
    
    {
        title: 'おひとりさま葬儀の喪主代行',
        id: 'daiko',
        discript: '近くに親戚がいない、身寄りがいないなどの方のために喪主代行を行います。会葬者への連絡、返礼品の受け渡しなど事前契約通り葬儀が執り行われるように代行いたします。',
        index: 2,
        image: image3
    },
    {
        title: 'お墓へのご納骨代行',
        id: 'higogimu',
        className: 'higogimu', 
        discript: '納骨まで依頼できる親類がいない方へ、指定の場所へのご納骨を代行いたします。寺院へのご連絡海洋散骨、樹木葬にもご対応。',
        index: 3,
        image: image4
        
    },
    
    {
        title: '粉骨の実施',
        id: 'ikotsu', 
        discript: '散骨をお考えの方へ粉骨を実施いたしています。粉骨はお遺骨と分からない程度に粉末化を行います。ご郵送での受付も行っています。お気軽にご相談ください。',
        index: 4,
        image: image5
    }
]